import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout title={siteTitle}>
        <SEO title="404: Strony nie znaleziono" />
        <div className="columns" style={{ padding: "10% 10% 5% 10%" }}>
          <div className="column" style={{ textAlign: "center" }}>
            <Link to="/">
              <img
                alt="404 Not Found"
                loading="lazy"
                src="/img/grafika-404.png"
                style={{ minWidth: "250px" }}
                height="auto"
                width="auto"
              />
            </Link>
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="/">
              <h1
                className="title"
                style={{
                  fontSize: "2em",
                  fontWeight: "bold",
                  color: "#00ff00"
                }}
              >
                404
              </h1>
            </Link>
            <br />
            <h2 className="title is-size-5">
              <strong>
                Nie znaleziono strony &bull; Page not found <br />
                <br /> Seite nicht gefunden &bull; 페이지를 찾을 수 없음
              </strong>
            </h2>
            <p className="is-size-5">
              Bardzo nam przykro ale strona o podanym adresie nie została
              znaleziona. <br />
              <br />
              We are sorry but the page with this address has not been found.
              <br />
              <br />
              Es tut uns leid, aber die Seite mit dieser Adresse wurde nicht
              gefunden.
              <br />
              <br />
              죄송합니다. 이 주소의 페이지를 찾을 수 없습니다.
            </p>
            <br />
            <button
              className="button rexer-button is-size-4"
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ marginBottom: "50px" }}
            >
              HOME
            </button>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
